<template>
  <v-autocomplete
    v-model="valueMutation"
    v-bind="$attrs"
    outlined
    clearable
    chips
    :search-input.sync="search"
    :items="selectList"
    cache-items
    item-value="id"
    item-text="fullText"
    item-disabled="deleted_at"
    :loading="loadingAction"
    placeholder="Выберите клиента"
    v-on="$listeners"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.client_name_display }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="(typeof data.item !== 'object')">
        <v-list-item-content v-text="data.item" />
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.client_name_display" />
          <v-list-item-subtitle v-html="data.item.phone" />
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text
            v-if="data.item.deleted_at"
            class="error--text"
            v-text="'удален'"
          />
        </v-list-item-action>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'AccountAutocomplete',
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object, Array], default: undefined },
      programId: { type: [Number], default: undefined },
      // serverSearch: { type: Boolean, default: true },
      withTrashed: { type: Boolean, default: false },
      limit: { type: Number, default: 10 },
    },
    data () {
      return {
        loadingAction: false,
        search: null,
      }
    },
    computed: {
      ...mapGetters({
        globalProgramId: 'programId',
        accountShortList: 'account/account/accountShortList',
      }),
      accountProgramId () {
        return this.programId || this.globalProgramId
      },
      selectList () {
        return this.accountShortList.map((item) => {
          const fullText = [item.fio, item.client_name, item.phone, item.email, item.refer_code].join(' ')

          return Object.assign({}, item, {
            phone: '+' + item.phone,
            client_name_display: item.client_name || this.$t('no_name'),
            fullText,
          })
        })
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    watch: {
      accountProgramId (v) {
        this.getItems()
      },
      search (val) {
        val && this.getItems(val)
      },
    },
    created () {
      this.getItems()
    },
    methods: {
      ...mapActions({
        getAccountShortList: 'account/account/getAccountShortList',
      }),
      remove (item) {
        if (Array.isArray(this.valueMutation)) {
          this.valueMutation = this.valueMutation.filter(v => v !== item.id)
        } else {
          this.valueMutation = null
        }
      },
      async getItems (search) {
        try {
          this.loadingAction = true
          await this.getAccountShortList({
            programId: this.accountProgramId,
            withTrashed: this.withTrashed,
            search: search,
            limit: this.limit,
          })
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
